.TypeEffect {
    font-size: 24px;
}


@media only screen and (max-width: 600px) {
    .TypeEffect {

        font-size: 18px;
    }
}