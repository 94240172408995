#resume-btn {
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    margin-top: 20px;
    background-color: #431f47;
    color: white;
}

#resume-btn:hover {
    /* border: 1px solid white;  */
    background-color: #9067C6;
    transition: all 0.3s ease-in-out;
}

.section-hero {
    margin-top: 20px;
}

.hero-left-image img {
    width: 100%;
}

.hero-right-image img {
    width: 90%;
    transform: scaleX(-1);
}

.HomeText {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.HomeText h1 b {
    color: #9067C6;
}

.illustration {
    transform: scaleX(1);
    height: 400px;
}

@media only screen and (max-width: 600px) {
    .HomeText {
        align-items: center;
        gap: 10px;
        margin: 30px 0;
    }

    .hero-right-image {

        text-align: center;
    }

}