.section-footer {
    margin: 20px 0;
    position: relative;
}

.section-footer a {
    color: white;
}

.footer-ic {
    display: flex;
    justify-content: space-between;
}

.f-ic {
    font-size: 30px;
    cursor: pointer;
}

.f-ic:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
    color: #9067C6;

}

@media only screen and (max-width: 600px) {

    /* CSS rules for phones here */
    .footer-ic {
        margin-top: 20px;
    }
}