.section-tech-stack {
  margin: 40px 0;
}

.my-card {
  border: 2px solid #9067C6 !important;
  background-color: #031926 !important;
  display: flex;
  align-items: center;
  padding: 10px;
  height: 100px !important;
  color: #9067C6 !important;
}

.my-card img {
  width: 40px;
}


.my-card:hover {
  cursor: pointer;
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px #fff;
  transition: all 0.2s ease-in-out;
  background-color: #1F1F24;
  color: whitesmoke;
}